import { Ref, ref, watch, computed } from "@vue/composition-api"

import { tryTranslation } from "@/_i18n"
import { I18n_T } from "@/_i18n/types"

import { ErrorResponse, FetchDataResult } from "@/_service/http"

/**
 * Types of errors that are possible
 */
export enum ErrorType {
  Error = "error",

  MissingValue = "missing.value",

  ExtraValue = "extra.value",

  InvalidValue = "invalid.value",

  ObjectDoesNotExist = "object.does.not.exist",

  ObjectAlreadyExists = "object.already.exists",

  ObjectState = "invalid.object.state",

  DB = "db",

  Authentication = "authentication",

  Authorization = "authorization",
}

/**
 * map of custom handle for error types
 */
export type CustomHandler = {
  [type in ErrorType]?: (
    result: ErrorResponse
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ) => { key: string; params?: { [key: string]: any } } | undefined
}

/**
 * error keys, which should not be translated
 */
const IDENTIFIER_KEYS = ["id", "state"]

const translateParams = (
  t: I18n_T,
  params?: {
    [key: string]: string
  }
): {
  [key: string]: string
} => {
  const translated: {
    [key: string]: string
  } = {}

  if (params) {
    Object.keys(params).forEach((key) => {
      translated[key] = !IDENTIFIER_KEYS.includes(key)
        ? tryTranslation(t, "error.params.", params[key])
        : params[key]
    })
  }

  return translated
}

/**
 * Generate a translated error message from the api service result
 *
 * @param result        the result from the api service
 * @param t             the translator function
 * @param customHandler custom error handlers for custom messages
 * @returns             a error message. can be {@code null} and if the error
 *                      should be visible i.e. if the result is successful
 */
export const useErrorHandling = <T>(
  result: Ref<FetchDataResult<T>>,
  t: I18n_T,
  customHandler?: CustomHandler
): {
  message: Ref<string | undefined>
  isVisible: Ref<boolean>
} => {
  const messageKey = ref<
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    { key: string; params?: { [key: string]: any } } | undefined
  >(undefined)

  const message = computed<string>(() =>
    messageKey.value
      ? (t(messageKey.value.key, messageKey.value.params) as string)
      : ""
  )
  const isVisible = ref(false)

  watch(
    result,
    () => {
      isVisible.value = !result.value.success

      if (isVisible.value) {
        const errorResponse = result.value.data as ErrorResponse

        const errorType = errorResponse?.type?.toLowerCase()

        console.error("Response error message:", errorResponse?.message)

        if (errorType && !!customHandler?.[errorType as ErrorType]) {
          messageKey.value = customHandler?.[errorType as ErrorType]?.(
            errorResponse
          )
          if (message.value !== undefined) return
        }
        messageKey.value = errorType
          ? {
              key: `error.responseTypes.${errorType?.replace(/\./g, "-")}`,
              params: translateParams(t, errorResponse?.errorParameters),
            }
          : { key: `error.unknown` }
      } else {
        messageKey.value = undefined
      }
    },
    { deep: true }
  )

  return {
    message,
    isVisible,
  }
}
