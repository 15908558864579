





















import { defineComponent } from "@vue/composition-api"

import ErrorAlert from "./ErrorAlert.vue"

export default defineComponent({
  name: "Loader",

  components: { ErrorAlert },

  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },
    errorMessage: {
      type: [String, Boolean],
      default: undefined,
    },
    height: {
      type: [String, Number],
      default: undefined,
    },
    type: {
      type: String,
      default: "list-item",
    },
  },
})
