import {
  computed,
  ComputedRef,
  reactive,
  ref,
  Ref,
  UnwrapRef,
  watch,
} from "@vue/composition-api"

import { I18n_T } from "@/_i18n/types"

import {
  encodeDataToQueryString,
  END_POINT_BASE_URL,
  FetchDataResult,
  Response,
} from "@/_service/http"

import { CustomHandler, useErrorHandling } from "./errorHandling"
import { useI18n } from "@/_i18n"
import { Dictionary } from "vue-router/types/router"

/**
 * hook for using an api call
 *
 * @param apiCall       the function that calls the backend
 * @param t             the translator function
 * @param data          the data that should be passed to backend
 * @param successCb     callback function to be called in success case
 * @param errorHandler  custom error handlers for custom messages
 * @returns             a loading boolean.
 *                      a function to initialize the submit.
 *                      an error message.
 *                      if the result is successful
 *                      the result itself
 */
export function useApiCall<P, R>(
  apiCall: (data: P) => Promise<FetchDataResult<R>>,
  t: I18n_T,
  data: P,
  successCb?: (response?: R) => void,
  errorHandler?: CustomHandler
): {
  isLoading: Ref<boolean>
  onSubmit: () => Promise<void>
  errorMessage: Ref<string | undefined>
  hasErrors: Ref<boolean>
  result: Ref<UnwrapRef<FetchDataResult<R>>>
  submittedParameter: Ref<UnwrapRef<P | undefined>>
  reset: () => void
} {
  const result = ref<FetchDataResult<R>>({ success: true })

  const { isVisible, message } = useErrorHandling<R>(
    result as Ref<FetchDataResult<R>>,
    t,
    errorHandler
  )

  const submittedParameter = ref<P | undefined>(undefined)

  const isLoading = ref(false)

  const onSubmit = async (): Promise<void> => {
    isLoading.value = true
    result.value = { success: true }
    submittedParameter.value = ref({ ...data }).value
    result.value = await apiCall(data)
    if (result.value.success && successCb) {
      successCb(result.value.data as R)
    }
    isLoading.value = false
  }

  return {
    isLoading,
    onSubmit,
    errorMessage: message,
    hasErrors: isVisible,
    result,
    submittedParameter,
    reset: () => (result.value = { success: true }),
  }
}

/**
 * create reactive link to backend endpoint
 *
 * @param path      link path (operation)
 * @param params    data to be send (operation parameters)
 */
export function useLink(
  path: string,
  params: Dictionary<string> = {}
): ComputedRef<string> {
  return computed(() => {
    return createLink(path, params)
  })
}

/**
 * create link to backend endpoint
 *
 * @param path      link path (operation)
 * @param query     data to be send (operation parameters)
 */
export function createLink(path: string, query: Dictionary<string>): string {
  const queryString = encodeDataToQueryString(query)
  return `${END_POINT_BASE_URL}${path}${queryString ? `?${queryString}` : ""}`
}

/**
 * hook for using an API call by change of `submittedParameter`
 *
 * @param apiCall       the function that calls the backend
 * @param data          the initial request data to be passed to backend
 * @param t             the optional translator function
 * @returns             the object with properties related to API call
 */
// the TS compiler doesn't like `extends object`
// eslint-disable-next-line
export function useReactiveApiCall<P extends object, R>(
  apiCall: (data: P) => Promise<FetchDataResult<R>>,
  data: P,
  t = useI18n().t
): {
  /** the optional error message from response */
  errorMessage: Ref<string | undefined>
  /** the flag, indicating whether the API call was erroneous */
  hasErrors: Ref<boolean>
  /** the loading indicator (request is currently executed) */
  isLoading: Ref<boolean>
  /** the function to be used to enforce new backend request */
  refresh: () => Promise<void>
  /** the data object from response */
  resultData: ComputedRef<UnwrapRef<R> | Response | undefined>
  /** the reactive parameter to be used for request; if changed, the new request will be submitted */
  submittedParameter: UnwrapRef<P>
} {
  const submittedParameter = reactive<P>(data)

  const {
    errorMessage,
    hasErrors,
    isLoading,
    onSubmit: refresh,
    result,
  } = useApiCall<P, R>(apiCall, t, submittedParameter as P)

  watch(submittedParameter, refresh, { immediate: true })

  const resultData = computed(() => {
    return result.value?.data
  })

  return {
    isLoading,
    errorMessage,
    hasErrors,
    refresh,
    resultData,
    submittedParameter,
  }
}
